var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-column mid-container-full"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle,"balanceLoading":_vm.isBalanceLoading,"creditBalance":_vm.creditBalance},on:{"topUpDialog":function($event){return _vm.open('payment')}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"loading":_vm.pageLoading || _vm.inProgress,"small":"","color":"accent","icon":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('contact/list', {
                      page: _vm.paginate.page,
                      limit: _vm.paginate.limit,
                    })}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])])]},proxy:true}])})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('div',{staticClass:"container d-flex flex-column flex-grow-1 flex-shrink-1 mt-5 animate__animated animate__fadeIn",staticStyle:{"padding":"0px !important"}},[_c('h2',{staticClass:"ft font-weight-medium pa-1"},[_vm._v(" Contacts Management ")]),_c('p',{staticClass:"ft font-weight-medium px-2"},[_vm._v(" Quickly gain access to sending sms to multiple contacts by adding all your contacts onto the platform by uploading an "),_c('kbd',[_vm._v("Excel")]),_vm._v(" file, "),_c('kbd',[_vm._v("CSV")]),_vm._v(" or using a form to create it. ")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm mr-3 black--text mb-2",staticStyle:{"max-width":"600px"},attrs:{"filled":"","hide-details":"","rounded":"","loading":_vm.isLoading,"dense":"","prepend-inner-icon":"search","placeholder":"Search by name, phone number ..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.contacts.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 mt-5",staticStyle:{"margin-bottom":"250px"}},[(_vm.selected.length > 0)?_c('v-toolbar',{staticClass:"mx-1 animate__animated animate__fadeIn",attrs:{"color":"white","dense":"","flat":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.open('details')}}},on),[_c('span',{staticClass:"ft text-capitalize font-weight-medium"},[_vm._v("Send To "+_vm._s(_vm.selected.length)+" Contacts")])])]}}],null,false,741864778)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Send SMS To "+_vm._s(_vm.selected.length)+" Contacts")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.open('contacts')}}},on),[_c('span',{staticClass:"ft text-capitalize font-weight-medium"},[_vm._v("Send To All Contacts")])])]}}],null,false,1586933101)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Send SMS To All Contacts")])])],1):_c('div',{staticClass:"d-flex flex-grow-1 justify-end mb-2 animate__animated animate__fadeIn"},[_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('add')}}},[_c('span',{staticClass:"ft text-capitalize font-weight-medium font-size-sm"},[_vm._v("Add Contact")])]),_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('upload')}}},[_c('span',{staticClass:"ft text-capitalize font-weight-medium font-size-sm"},[_vm._v("Import Contacts")])]),_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('download')}}},[_c('span',{staticClass:"ft text-capitalize font-weight-medium font-size-sm"},[_vm._v("Export Contacts")])])],1),_c('v-data-table',{staticClass:"ft font-weight-medium vx-shadow font-size-md ma-1",attrs:{"items":_vm.contacts,"loading":_vm.pageLoading,"headers":_vm.headers,"item-key":"id","show-select":"","options":_vm.options,"server-items-length":_vm.paginate.total,"footer-props":{
                      itemsPerPageOptions: [30, 60, 90],
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
                    var item = ref.item;
return [_c('v-avatar',{staticClass:"white--text font-weight-bold font-size-md ma-1",attrs:{"color":"primary","size":"40"}},[_vm._v(_vm._s(item.name.charAt(0)))])]}},{key:"item.phone",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.phone))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.copyContact(item.phone)}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("copy_all")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Copy Phone Number")])])]}},{key:"item.groupContactsId",fn:function(ref){
                    var item = ref.item;
return [(item.groupContactsId.length > 0)?_c('div',[_vm._l((item.groupContactsId.slice(0, 2)),function(group){return _c('v-chip',{key:group.id,staticClass:"mx-1",attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v(_vm._s(group.groupId.name))])])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [(item.groupContactsId.slice(2).length > 0)?_c('span',_vm._g({staticClass:"ft font-weight-medium grey--text text--accent-3"},on),[_vm._v("+"+_vm._s(item.groupContactsId.slice(2).length)+" more")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.groupContactsId .slice(2) .map(function (group) { return group.groupId.name; }) .join(',')))])])],2):_c('div',[_c('v-chip',{staticClass:"ft mx-1 font-weight-medium text-sm pa-2",attrs:{"color":"accent","small":"","label":""}},[_vm._v(" No groups assigned ")])],1)]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.selected.length > 0,"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('details', item.id, 'contact/details')}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("sms")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Send SMS")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-menu',{attrs:{"transition":"slide-y-transition","close-on-content-click":false,"max-width":320,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var menu = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("group_add")])])]}}],null,true)},[_c('v-card',{staticClass:"d-flex ma-2 flex-column flex-grow-1 cursor-pointer",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('span',{staticClass:"ft text-sm mx-1 text-uppercase font-weight-medium"},[_vm._v("Assign To Group")])]),_c('v-card-text',{staticClass:"px-1 pt-1"},[_c('v-autocomplete',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"flat":"","outlined":"","label":"Choose a group","hint":"Group your contacts","dense":"","multiple":"","hide-details":"","items":_vm.groups.filter(
                                      function (group) { return !item.groupContactsId
                                          .map(function (group) { return group.groupId.id; })
                                          .includes(group.id); }
                                    ),"item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',{staticClass:"ft pa-1 ml-1 font-weight-medium text-sm"},[_vm._v(_vm._s(_vm.groups.length > 0 ? 'All groups have been assigned' : 'No groups added yet.'))])]},proxy:true},{key:"item",fn:function(ref){
                                    var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.assignGroups),callback:function ($$v) {_vm.assignGroups=$$v},expression:"assignGroups"}})],1),_c('v-card-actions',{staticClass:"pa-0 mt-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isLoading || _vm.assignGroups.length === 0,"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addToGroup(item.id)}}},[_c('span',{staticClass:"ft text-capitalize font-weight-medium text-sm"},[_vm._v("Save")])])],1)],1)],1)]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Add To Group")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('edit', item.id, 'contact/details')}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Edit Contact")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('delete', item.id, 'contact/details')}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Delete Contact")])])]}}],null,false,2788113155),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_c('EmptyPage',{staticClass:"animate__animated animate__fadeIn mt-4",attrs:{"image":require('@/assets/images/folder.svg'),"imageWidth":'125px',"title":_vm.search !== ''
                      ? ("No contacts found for the search term " + _vm.search)
                      : "No Contacts have been generated yet","subTitle":_vm.search !== ''
                      ? "All searched contact results will show up here!"
                      : "All added contacts will show up here !"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mt-5 mx-1",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading || _vm.inProgress,"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.open('add')}}},[_c('span',{staticClass:"ft font-weight-medium text-capitalize text-sm"},[_vm._v("Add Contact")])]),_c('v-btn',{staticClass:"mt-5 mx-1",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading || _vm.inProgress,"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.open('upload')}}},[_c('span',{staticClass:"ft font-weight-medium text-capitalize text-sm"},[_vm._v("Upload Contacts ")])]),(_vm.inProgress && _vm.contacts.length === 0)?_c('div',{staticClass:"d-flex flex-column mt-4",staticStyle:{"align-items":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}),_c('div',{staticClass:"text-sm font-weight-medium ft pt-2"},[_vm._v(" Contacts upload in progress, please wait ... ")])],1):_vm._e()]},proxy:true}],null,false,2520646661)})],1)])]):_c('InternetConnection',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mt-8 ft font-weight-bold text-sm text-capitalize",attrs:{"color":"accent","loading":_vm.pageLoading,"rounded":""},on:{"click":function($event){return _vm.$store.dispatch('contact/list', {
                    page: _vm.paginate.page,
                    limit: _vm.paginate.limit,
                  })}}},[_c('i',{staticClass:"material-icons-outlined mr-2"},[_vm._v("refresh")]),_vm._v(" Try Again ")])]},proxy:true}])})],1)],1)],1)]),_c('AddContactDialog',{attrs:{"state":_vm.dialog('add'),"loading":_vm.isLoading},on:{"actions":_vm.close,"contact":_vm.addContact}}),_c('EditContactDialog',{attrs:{"state":_vm.dialog('edit'),"dialogLoading":_vm.isDialogLoading,"loading":_vm.isLoading},on:{"actions":_vm.close,"contact":_vm.editContact}}),_c('DeleteDialog',{attrs:{"state":_vm.dialog('delete'),"message":'Once you delete this contact, this operation cannot be undone.',"loading":_vm.isLoading},on:{"actions":_vm.close,"delete":_vm.deleteContact}}),_c('BulkExportDialog',{attrs:{"state":_vm.dialog('download'),"loading":_vm.isLoading},on:{"actions":_vm.close}}),_c('BulkUploadDialog',{attrs:{"state":_vm.dialog('upload'),"loading":_vm.isLoading},on:{"actions":_vm.close,"uploadFile":_vm.bulkUpload}}),_c('SendSMSDialog',{attrs:{"state":_vm.dialog('details'),"credit-balance":_vm.creditBalance,"selectedContacts":_vm.selected,"loading":_vm.isLoading},on:{"actions":_vm.close,"sendSMS":_vm.sendSMS}}),_c('SendSMSToAllDialog',{attrs:{"state":_vm.dialog('contacts'),"totalSavedContacts":_vm.paginate.total,"credit-balance":_vm.creditBalance,"loading":_vm.isLoading},on:{"actions":_vm.close,"sendSMSToAll":_vm.sendSMSToAll}}),_c('AddSMSSenderID',{attrs:{"state":_vm.dialog('senderId'),"loading":_vm.isLoading},on:{"actions":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }